<!-- https://i.imgur.com/VS6Vhz5.png -->
<template>
    <div class="misc-user-picture">
        <NuxtLink no-prefetch :to="url">
            <MiscUserPic :class="imageClass" :user="user" :badge="user.role.name === 'Usuario'" />
        </NuxtLink>
        <div>
            <NuxtLink
                no-prefetch
                :to="url"
                class="misc-user-picture__user"
                :class="isSharing ? '' : 'hover:underline'"
            >
                {{ user.username }}
            </NuxtLink>
            <span v-if="user.role.name !== 'Usuario'" class="misc-user-picture__badge">
                <MiscBadge :text="user.role.name" :show-text-in-mobile="true" />
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'MicroFullUser',
    props: {
        isSharing: {
            type: Boolean,
            default: false,
        },
        imageClass: {
            type: String,
            default: 'w-10 h-10',
        },
        user: {
            type: Object as PropType<Models.User>,
            required: true,
        },
    },
    computed: {
        url(): object | string {
            return this.isSharing ? '#' : '/dashboard/' + this.user.id
        },
    },
})
</script>

<style lang="postcss" scoped>
.misc-user-picture {
    @apply flex w-full flex-row items-center gap-x-1.5;
    &__user {
        @apply ml-0.5 text-center text-xs sm:text-sm;
    }
    &__badge {
        @apply flex;
    }
}
</style>
