<template>
    <!-- https://i.imgur.com/qYYtXrX.png -->
    <div class="order-by-dropdown" :class="width">
        <ul class="order-by-dropdown-items">
            <li v-for="(option, key) in options" :key="key">
                <button type="button" class="order-by-dropdown-items_item" @click="$emit('selected', option)">
                    <p
                        class="order-by-dropdown-items_item-text"
                        :class="selected === option ? 'text-site-primary' : 'text-gray-800'"
                    >
                        {{ option }}
                    </p>
                </button>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'

export default defineComponent({
    name: 'DropdownOrderby',
    props: {
        options: {
            type: Array as PropType<string[]>,
            required: true,
        },
        selected: {
            type: String,
            required: true,
        },
        width: {
            type: String,
            default: '',
        },
    },
    data() {
        return {}
    },
})
</script>

<style lang="postcss" scoped>
.order-by-dropdown {
    @apply absolute right-0 top-full z-50 pt-2;

    &-items {
        @apply relative flex flex-col rounded-2xl border border-gray-200 bg-white p-2 shadow-2xl;

        &_item {
            @apply flex w-full items-center justify-between space-x-2 rounded p-1 transition-colors lg:hover:bg-gray-50;
            &:last-of-type {
                @apply border-none;
            }

            &-text {
                @apply self-center text-xs lg:text-sm;
            }

            input {
                @apply self-center;
            }
        }
    }
}
</style>
