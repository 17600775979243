<template>
    <div class="thread-index-page">
        <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />
        <div class="thread-index-page__content">
            <main>
                <div class="thread">
                    <div class="thread__author hidden flex-col p-4 sm:flex">
                        <NuxtLink
                            no-prefetch
                            :to="'/dashboard/' + threadData.forum.user.id"
                            class="thread__author-image"
                        >
                            <MiscUserPic :user="threadData.forum.user" />
                        </NuxtLink>
                        <NuxtLink
                            no-prefetch
                            :to="'/dashboard/' + threadData.forum.user.id"
                            class="thread__author-user"
                        >
                            {{ threadData.forum.user.username }}
                        </NuxtLink>
                        <span
                            v-if="
                                threadData.forum.user.role.name &&
                                threadData.forum.user.role.name !== 'Usuario'
                            "
                            class="thread__author-badge"
                        >
                            <MiscBadge :text="threadData.forum.user.role.name" />
                        </span>
                    </div>

                    <div class="about">
                        <div class="about-top hidden space-x-2 sm:flex">
                            <span class="time">{{ $lang.pages.forosSlug.ago }} {{ updatedDate }}</span>
                            <!-- START DROPDOWN REPORT -->
                            <div class="discount-dropdown">
                                <button
                                    type="button"
                                    class="discount-dropdown__button"
                                    @click="
                                        () =>
                                            dropdownToggle(
                                                !displayedItemFlags.showDiscountMenu,
                                                'showDiscountMenu',
                                            )
                                    "
                                >
                                    <img :src="$assets.gray.buttonDots" alt="icon" />
                                </button>
                                <transition name="drop-from-right">
                                    <DropdownOptions
                                        v-if="displayedItemFlags.showDiscountMenu"
                                        class="discount-dropdown__dropdown"
                                        :options="dropdownOptions.discountOptions"
                                        @close="
                                            () =>
                                                dropdownToggle(
                                                    !displayedItemFlags.showDiscountMenu,
                                                    'showDiscountMenu',
                                                )
                                        "
                                    />
                                </transition>
                            </div>
                            <!-- END DROPDOWN REPORT -->
                        </div>
                        <div class="about__title-and-subtitle">
                            <h1>{{ threadData.forum.title }}</h1>
                            <h2 v-if="threadData.forum.category">
                                <img :src="$assets.white.chat" alt="Mensajes" />
                                {{ threadData.forum.category.name }}
                            </h2>
                        </div>
                        <div class="about__description" v-html="descriptionSanitized" />
                        <div class="mt-4 flex justify-between">
                            <!-- USER PROFILE MOBILE -->
                            <div class="flex flex-row items-center gap-x-1 sm:hidden">
                                <MiscUserPic :user="threadData.forum.user" :badge="true" />
                            </div>

                            <div class="top relative flex items-end gap-2 sm:hidden">
                                <span class="text-xs text-gray-800">Hace {{ updatedDate }}</span>
                                <!-- DROPDOWN MOBILE -->
                                <div class="discount-dropdown">
                                    <button
                                        type="button"
                                        class="discount-dropdown__button"
                                        @click="
                                            () =>
                                                dropdownToggle(
                                                    !displayedItemFlags.showDiscountMenuMobile,
                                                    'showDiscountMenuMobile',
                                                )
                                        "
                                    >
                                        <img :src="$assets.gray.buttonDots" alt="icon" />
                                    </button>
                                    <transition name="drop-from-right">
                                        <DropdownOptions
                                            v-if="displayedItemFlags.showDiscountMenuMobile"
                                            class="discount-dropdown__dropdown"
                                            :options="dropdownOptions.discountOptions"
                                            @close="
                                                () =>
                                                    dropdownToggle(
                                                        !displayedItemFlags.showDiscountMenuMobile,
                                                        'showDiscountMenuMobile',
                                                    )
                                            "
                                        />
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <client-only>
                    <h2 class="comments_heading">{{ $lang.pages.forosSlug.comments }}</h2>

                    <div id="discount-comments-section" class="comments_wrapper">
                        <div class="comments-top">
                            <img class="comments-top__icon" :src="$assets.black.comment" alt="icon" />
                            <span>{{ threadData.comments.length }}</span>
                            <button
                                class="comments-top__follow-button"
                                :class="{ following: follow }"
                                type="button"
                                @click="follow = !follow"
                            >
                                {{ follow ? $lang.pages.forosSlug.following : $lang.pages.forosSlug.follow }}
                            </button>
                        </div>
                        <div class="px-4">
                            <CInputMakeComment
                                class="mb-3"
                                :user="AuthStore.UserData || undefined"
                                :floated-submit="true"
                                :replying="creatingNewComment"
                                :reply-callback="createComment"
                            />
                            <div class="comment-dropdown">
                                <button
                                    type="button"
                                    class="comment-dropdown__button"
                                    @click="
                                        () =>
                                            dropdownToggle(
                                                !displayedItemFlags.showCommentMenu,
                                                'showCommentMenu',
                                            )
                                    "
                                >
                                    <span>{{
                                        nextPageQuery.order_by === 'most-liked'
                                            ? $lang.pages.forosSlug.more_relevant
                                            : $lang.pages.forosSlug.more_recents
                                    }}</span>
                                    <img :src="$assets.gray.arrowDown" alt="icon" />
                                </button>
                                <transition name="drop-from-left">
                                    <DropdownOptions
                                        v-if="displayedItemFlags.showCommentMenu"
                                        class="comment-dropdown__dropdown"
                                        :active="activeFilter"
                                        :options="dropdownOptions.commentOptions"
                                        @close="
                                            () =>
                                                dropdownToggle(
                                                    !displayedItemFlags.showCommentMenu,
                                                    'showCommentMenu',
                                                )
                                        "
                                    />
                                </transition>
                            </div>
                            <template v-if="comments.length > 0">
                                <ul class="space-y-4">
                                    <li v-for="(comment, index) in comments" :key="index">
                                        <CCardCommentThread :comment="comment" />
                                    </li>
                                </ul>
                                <ButtonLoadMore
                                    v-if="displayedItemFlags.showCommentsLoadButton"
                                    class="mb-1 mt-3"
                                    :handler="loadCommentsButtonHandler"
                                    :loading="loadingItemFlags.commentsLoadButton"
                                />
                            </template>
                            <MiscNoResults
                                v-else
                                mode="comments"
                                :title="$lang.pages.forosSlug.no_comments_yet"
                                :subtitle="$lang.pages.forosSlug.comment_first"
                            />
                        </div>
                    </div>
                </client-only>
            </main>

            <aside class="sidebar">
                <BWidgetShare :title="threadData.forum.title" />
                <div class="sidebar-discussions">
                    <div class="head">
                        <div class="head__title">
                            <img :src="$assets.gray.replies" alt="icono de respuestas" />
                            <span>{{ $lang.pages.forosSlug.discussions }}</span>
                        </div>
                        <div class="dropdown">
                            <button
                                class="dropdown__order-button"
                                @click="dropdownToggleEasy(!orderDropdown.show)"
                            >
                                <img :src="$assets.primary.filterBy" alt="" />
                                {{ orderDropdown.selected }}
                                <div ref="$orderDropdown">
                                    <DropdownOrderby
                                        v-if="orderDropdown.show"
                                        :options="orderDropdown.data"
                                        :selected="orderDropdown.selected"
                                        @selected="($event) => (orderDropdown.selected = $event)"
                                    />
                                </div>
                            </button>
                        </div>
                    </div>
                    <ul>
                        <li v-for="item in threadData.discussions.results" :key="item.id">
                            <NuxtLink no-prefetch :to="`/${$lang.routes.forum}/${item.slug}`" class="title">{{
                                item.title
                            }}</NuxtLink>

                            <div class="user-and-messages">
                                <div class="user-and-messages__author">
                                    <MiscFullUserPic :user="item.user" :badge="true" class="h-8 w-8" />
                                    <span class="answers">
                                        <img :src="$assets.gray.replies" alt="icono de respuestas" />
                                        {{ item.comments_count }}
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="pagination.discussions.show"
                            :loading="pagination.discussions.loading"
                            :handler="() => loadMoreDiscussions()"
                            class="see-more"
                        >
                            <div class="see-more_btn">
                                <div v-if="!pagination.discussions.loading">
                                    {{ $lang.pages.forosSlug.see_more }}
                                    <img :src="$assets.primary.arrowDownDouble" alt="Flecha doble ver mas" />
                                </div>
                                <img
                                    v-else
                                    :src="$assets.primary.loading"
                                    height="17"
                                    width="17"
                                    alt="icon"
                                    class="spin"
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { formatDuration, intervalToDuration } from 'date-fns'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

import { removeXSS, replacePlaceholders } from '~/util/textFunction'

import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { variants } = useVariantsDictionary()
const { currentDate } = useDateFunctions()

const { $lang, $assets, $bp } = useNuxtApp()
const Route = useRoute()

const $orderDropdown = ref()

onClickOutside($orderDropdown, () => (orderDropdown.show = false))
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const getThread = (query?: any) =>
    $fetch<Api.Responses.Pages.Thread | Api.Responses.ResourceNotFound>(
        endpoints.pages.thread.path.replace('_SLUG_', Route.params.slug as string),
        {
            headers: buildHeaders(),
            method: 'GET',
            baseURL,
            params: {
                ...query,
            },
        },
    )

const { data: responseData, error } = await useAsyncData('chat-response-data', () =>
    getThread().catch((e) => e.data),
)

if (responseData.value?.feedback !== 'data_success') {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

let threadData = ref(responseData.value.data)

const { current_page: current_page_discussions, last_page: last_page_discussions } =
    threadData.value.discussions.meta

const pagination = {
    discussions: {
        show: true,
        loading: false,
    },
}

if (!last_page_discussions || current_page_discussions === last_page_discussions) {
    pagination.discussions.show = false
}

const type = variants.forum

let commentsLoaded = 0

threadData.value.comments.forEach((c: any) => {
    commentsLoaded++
    c.comments?.forEach(() => {
        commentsLoaded++
    })
})

const showCommentsLoadButton = (threadData.value.forum.comments_count || 0) > commentsLoaded

const currentDates = currentDate()

const {
    public: { origin, siteName },
} = useRuntimeConfig()

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':day', String(currentDates.day) || ''],
        [':site', siteName || ''],
    ],
    [threadData.value.forum.seo?.title || '', threadData.value.forum.seo?.description || ''],
)

const creatingComment = ref(false)
const activeFilter = ref(0)
const follow = ref(false)

const tabsData = reactive({
    discussions: [...threadData.value.discussions.results],
})

const displayedItemFlags = reactive({
    showCommentsLoadButton,
    showCommentMenu: false,
    showDiscountMenu: false,
    showDiscountMenuMobile: false,
})

const nextPageQuery = reactive({
    commentable_type: type.variant,
    commentable_id: threadData.value.forum.id,
    per_page: 15,
    page: 2,
    order_by: 'most-liked',
})

const linkToCommentId = ref(null) as Ref<null | number>
const loadingItemFlags = reactive({
    commentsLoadButton: false,
})

const comments = ref([]) as Ref<Models.Comment[]>

const orderDropdown = reactive({
    show: false,

    data: [$lang.pages.forosSlug.popular, $lang.pages.forosSlug.new],
    selected: $lang.pages.forosSlug.popular,
})

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const { reportPost, createNewComment, creatingNewComment } = usePostActions()
const RootStore = useRootStore()
const AuthStore = useAuthStore()

const createReport = async (reason: Parameters<typeof reportPost>[0]['reason']) => {
    try {
        const reportResult = await reportPost({
            reason,
            reportable_type: type.variant,
            reportable_id: threadData.value.forum.id,
        })

        if (!reportResult) {
            return
        }
        if (reportResult.feedback === 'data_success') {
            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.views.discountDetails.okay,
                text: $lang.views.discountDetails.report_success,
                type: 'success',
            })
        } else if (
            AuthStore.IsAuth &&
            AuthStore.UserData?.accepted_conditions_at &&
            AuthStore.UserData?.verified_email
        ) {
            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.views.discountDetails.close,
                text: $lang.views.discountDetails.report_error,
                type: 'error',
            })
        }
    } catch (e) {}
}

const dropdownOptions = computed(() => {
    return {
        commentOptions: [
            {
                title: $lang.views.discountDetails.more_relevant,
                icon: $assets.primary.bookmark,
                description: $lang.views.discountDetails.see_comments_relevant,
                handler: () => {
                    nextPageQuery.order_by = 'most-liked'
                    activeFilter.value = 0
                },
            },
            {
                title: $lang.views.discountDetails.more_recent,
                description: $lang.views.discountDetails.see_all_comments_more_recent_first,
                icon: $assets.primary.subscribe,
                handler: () => {
                    nextPageQuery.order_by = 'latest'
                    activeFilter.value = 1
                },
            },
        ],
        discountOptions: [
            {
                title: $lang.views.discountDetails.report,
                icon: $assets.primary.shield,
                description: $lang.views.discountDetails.why_report,
                subDropdown: true,
                options: [
                    {
                        title: $lang.views.discountDetails.spam_ofensive,
                        icon: $assets.primary.span,
                        handler: () => {
                            createReport('Spam/Ofensivo')
                        },
                    },
                    {
                        title: $lang.views.discountDetails.duplicated,
                        description: $lang.views.discountDetails.offer_is_published,
                        icon: $assets.primary.copyCode,
                        handler: () => {
                            createReport('Duplicado')
                        },
                    },
                    {
                        title: $lang.views.discountDetails.refered_links,
                        icon: $assets.primary.referralLink,
                        handler: () => {
                            createReport('Enlaces referidos')
                        },
                    },
                    {
                        title: $lang.views.discountDetails.other,
                        icon: $assets.primary.about,
                        handler: () => {
                            createReport('Otro')
                        },
                    },
                ],
            },
        ],
    }
})

const descriptionSanitized = computed(() => {
    return removeXSS(threadData.value.forum.description)
})

const updatedDate = computed(() => {
    const duration = intervalToDuration({
        start: new Date(threadData.value.forum.created_at as string),
        end: new Date(),
    })

    const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
    const units_spanish = [
        $lang.pages.forosSlug.years,
        $lang.pages.forosSlug.months,
        $lang.pages.forosSlug.weeks,
        $lang.pages.forosSlug.days,
        $lang.pages.forosSlug.hours,
        $lang.pages.forosSlug.minutes,
        $lang.pages.forosSlug.seconds,
    ]
    const nonzero = Object.entries(duration)
        .filter(([_, value]) => value)
        .map(([unit, _]) => unit)

    const formattedDuration = formatDuration(duration, {
        format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1) as any,
        delimiter: ', ',
    })

    return formattedDuration.replace(/\b(years|months|weeks|days|hours|minutes|seconds)\b/g, (match) => {
        return units_spanish[units.indexOf(match)]
    })
})

const breadcrumbs = computed(() => {
    return [
        {
            path: '/',
            title: $lang.pages.forosSlug.home,
        },
        {
            path: `/${$lang.routes.forum}`,
            title: $lang.pages.forosSlug.forums,
        },
        {
            path: `/${$lang.routes.forum}`,
            title: threadData.value.forum.category?.name || '',
        },
        {
            title: threadData.value.forum.title || '',
        },
    ]
})

const nextTabPage = computed(() => {
    return threadData.value.discussions.meta.current_page + 1
})

const orderByWatcher = computed(() => nextPageQuery.order_by)
const dropdownSelectedWatcher = computed(() => orderDropdown.selected)

dropdownSelectedWatcher

watch(orderByWatcher, () => loadComments())
watch(dropdownSelectedWatcher, () => loadMoreDiscussions(dropdownSelectedWatcher.value))

const scrollToCommentsSection = useScrollToComponent()

const loadMoreDiscussions = async (order_changed: string | false = false) => {
    pagination.discussions.loading = true

    const newInfo = JSON.parse(JSON.stringify(threadData)) as Api.Responses.Pages.Thread['data']

    let resultsOrder = null

    if (order_changed) {
        switch (order_changed) {
            case $lang.pages.forosSlug.new:
                resultsOrder = 'latest'
                break
            case $lang.pages.forosSlug.popular:
                resultsOrder = 'popular'
                break
        }
    }

    const result = await getThread({
        page: order_changed ? '' : nextTabPage,
        'order-by': resultsOrder,
    })

    if (result.feedback === 'data_success') {
        if (order_changed === false) {
            newInfo.discussions.results = []
            newInfo.discussions.meta = result.data.discussions.meta

            tabsData.discussions = [...tabsData.discussions, ...result.data.discussions.results]
            newInfo.discussions.meta = result.data.discussions.meta

            newInfo.discussions.results.push(...result.data.discussions.results)
            if (result.data.discussions.meta) {
                const { current_page, last_page } = result.data.discussions.meta
                if (!last_page || current_page === last_page) {
                    pagination.discussions.show = false
                }
            }
        } else if (order_changed) {
            newInfo.discussions = result.data.discussions
        }
    }

    threadData.value = newInfo

    pagination.discussions.loading = false
}

/* createNewComment */

const createComment = async (html: string) => {
    if (!AuthStore.IsAuth) {
        AuthStore.setAuthModal({
            type: 'sign-in',
        })
        return false
    }
    if (!AuthStore.UserData?.verified_email) {
        AuthStore.setAuthModal({
            type: 'email-verification',
            info: {
                isReminder: true,
                message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_comment} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
            },
        })
        return false
    }
    return await createNewComment({
        commentable_id: threadData.value.forum.id,
        commentable_type: 'forum',
        content: html,
    })
}

const loadComments = async () => {
    const commentsResult = await $fetch<Api.Responses.General.Comments>(endpoints.general.comments.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
        params: nextPageQuery,
    })

    if (commentsResult.feedback === 'data_success') {
        if (commentsResult.data.meta.current_page === 1) {
            comments.value = [...commentsResult.data.results]
        } else {
            const resolvedComments = linkToCommentId.value
                ? commentsResult.data.results.filter((comment) => comment.id !== linkToCommentId.value)
                : commentsResult.data.results

            comments.value = [...comments.value, ...resolvedComments]
        }

        if (commentsResult.data.meta.current_page < commentsResult.data.meta.last_page) {
            nextPageQuery.page = commentsResult.data.meta.current_page + 1
        } else {
            displayedItemFlags.showCommentsLoadButton = false
        }
    }
}

const loadCommentsButtonHandler = async () => {
    if (!loadingItemFlags.commentsLoadButton) {
        loadingItemFlags.commentsLoadButton = true
        await loadComments()
        loadingItemFlags.commentsLoadButton = false
    }
}

const dropdownToggleEasy = (show: boolean) => {
    orderDropdown.show = show
}

const dropdownToggle = (
    show: boolean,
    key: 'showCommentsLoadButton' | 'showCommentMenu' | 'showDiscountMenu' | 'showDiscountMenuMobile',
) => {
    if (
        ($bp.mb || $bp.xs || $bp.sm) &&
        (key === 'showCommentMenu' || key === 'showDiscountMenu' || key === 'showDiscountMenuMobile')
    ) {
        RootStore.setSiteDropdown(
            show
                ? dropdownOptions.value[key === 'showCommentMenu' ? 'commentOptions' : 'discountOptions']
                : null,
        )
    } else {
        displayedItemFlags[key] = show
    }
}
</script>

<style lang="postcss" scoped>
.thread-index-page {
    @apply container;
    &__content {
        @apply gap-3 space-y-5 pb-5 lg:grid lg:grid-cols-10 lg:space-y-0;
    }

    main {
        @apply space-y-5 lg:order-1 lg:col-span-8 lg:col-start-1;
        .thread {
            @apply flex w-full rounded-2xl border bg-white p-3;
            &__author {
                @apply w-[160px] justify-start space-y-2;
                &-image {
                    @apply mx-auto h-12 w-12 flex-none rounded-full sm:h-16 sm:w-16;
                    img {
                        @apply h-full w-full rounded-full object-cover;
                    }
                }
                &-user {
                    @apply text-center text-sm hover:underline sm:text-base;
                }
                &-badge {
                    @apply flex justify-center;
                }
            }
            .about {
                @apply flex w-full flex-col overflow-hidden;
                &-top {
                    @apply justify-end gap-1 text-xs text-gray-800;
                }
                &__title-and-subtitle {
                    h1 {
                        @apply text-xl font-semibold text-gray-800;
                    }
                    h2 {
                        @apply flex items-center gap-1 text-sm font-medium text-gray-800;
                        img {
                            @apply h-4 w-4;
                        }
                    }
                }
                &__description {
                    @apply prose mt-4 flex-grow text-gray-800;
                    > * {
                        @apply break-words;
                    }
                }
            }
        }
        .comments_heading {
            @apply mb-3 mt-5 text-xl font-semibold;
        }
        .comments_wrapper {
            @apply border-y bg-white py-4 xs:rounded-xl xs:border-x lg:py-2;
            .comments-top {
                @apply mb-3 flex items-center space-x-2 border-b px-4 pb-2 text-sm font-semibold;
                &__icon {
                    @apply h-4 w-4;
                }
                &__follow-button {
                    @apply rounded-full border border-site-primary px-4 py-0.5 text-xs font-medium text-site-primary transition-colors;
                    &.following {
                        @apply bg-site-primary text-white;
                    }
                }
            }
        }
    }

    .sidebar {
        @apply space-y-5 lg:order-2 lg:col-span-2 lg:col-start-9;
        &-discussions {
            @apply rounded-2xl border border-opacity-60 bg-white;
            .head {
                @apply flex justify-between gap-3 border-b p-4;
                &__title {
                    @apply flex items-center gap-1 font-medium text-gray-800;
                    span {
                        @apply text-lg lg:hidden 2xl:block 2xl:text-base;
                    }
                    img {
                        @apply h-6 lg:h-6 2xl:h-4;
                    }
                }
                .dropdown {
                    @apply flex items-center gap-1;
                    &__order-button {
                        @apply relative flex gap-1 rounded-lg border border-gray-200 bg-white p-1 px-4 text-xs font-semibold text-site-primary md:text-base;
                        img {
                            @apply h-3 self-center;
                        }
                    }
                }
            }
            ul {
                li {
                    @apply relative flex w-full flex-col gap-3 bg-white p-2 text-sm text-gray-800;
                    &:not(:last-of-type) {
                        @apply border-b;
                    }
                    .title {
                        @apply line-clamp-2 font-medium text-gray-800 hover:underline;
                    }
                    .user-and-messages {
                        @apply flex items-center;
                        &__author {
                            .answers {
                                @apply absolute bottom-0 right-1 flex items-center gap-1 text-sm font-medium text-gray-800;
                                img {
                                    @apply h-2.5;
                                }
                            }
                        }
                    }
                }
                .see-more {
                    @apply flex rounded-b-2xl font-semibold text-site-primary;
                    &_btn {
                        @apply flex items-center justify-center gap-1 rounded-lg p-0.5;
                        img {
                            @apply h-4;
                        }
                    }
                }
            }
        }
    }
}

.comment-dropdown {
    @apply relative z-10 mb-3;
    &__button {
        @apply flex items-center space-x-2 brightness-0;
        img {
            @apply w-4;
        }
    }
    &__dropdown {
        @apply absolute left-0 top-full w-60 rounded-xl;
    }
}
.discount-dropdown {
    @apply relative z-10 h-4;
    &__button {
        @apply h-4 w-4 flex-none;
        img {
            @apply h-full w-full object-contain;
        }
    }
    &__dropdown {
        @apply absolute right-0 top-full w-60 rounded-xl;
    }
}
</style>
